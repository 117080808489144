@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/amazon-ember-bold.68658972.woff2) format("woff2"), url(/static/media/amazon-ember-bold.bce18be8.woff) format("woff"), url(/static/media/amazon-ember-bold.b9e92b64.ttf) format("truetype");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 200;
  src: url(/static/media/amazon-ember-light.0f85a003.woff2) format("woff2"), url(/static/media/amazon-ember-light.ebdfb7c3.woff) format("woff"), url(/static/media/amazon-ember-light.04d5df91.ttf) format("truetype");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/amazon-ember-regular.6131b658.woff2) format("woff2"), url(/static/media/amazon-ember-regular.a4545433.woff) format("woff"), url(/static/media/amazon-ember-regular.5e4033e3.ttf) format("truetype");
}
